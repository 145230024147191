<template>
  <div class="p-6 flex-auto">
    <h2 class="text-xl font-semibold text-gray-800 mb-3">API key</h2>
    <div class="flex items-center">
      <div class="flex flex-col">
        <div class="text-sm text-gray-600 font-medium mb-1">API key</div>
        <div
          class="h-[38px] w-[350px] mr-2.5 border border-gray-200 rounded flex items-center px-3"
          :class="!active_project ? 'border-gray-200 bg-gray-100 text-gray-400 cursor-not-allowed' : 'cursor-pointer '"
          @click="copyToken()"
        >
          <svg
            class="mr-2.5"
            :disabled="true"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3.9375 14.4844H9.84375C10.0758 14.4844 10.2656 14.2945 10.2656 14.0625V12.375H11.5312V14.0625C11.5312 14.9933 10.7745 15.75 9.84375 15.75H3.9375C3.00542 15.75 2.25 14.9933 2.25 14.0625V8.15625C2.25 7.22549 3.00542 6.46875 3.9375 6.46875H5.625V7.73438H3.9375C3.70441 7.73438 3.51562 7.92422 3.51562 8.15625V14.0625C3.51562 14.2945 3.70441 14.4844 3.9375 14.4844ZM6.46875 3.9375C6.46875 3.00542 7.22549 2.25 8.15625 2.25H14.0625C14.9933 2.25 15.75 3.00542 15.75 3.9375V9.84375C15.75 10.7745 14.9933 11.5312 14.0625 11.5312H8.15625C7.22549 11.5312 6.46875 10.7745 6.46875 9.84375V3.9375ZM8.15625 10.2656H14.0625C14.2945 10.2656 14.4844 10.0758 14.4844 9.84375V3.9375C14.4844 3.70441 14.2945 3.51562 14.0625 3.51562H8.15625C7.92422 3.51562 7.73438 3.70441 7.73438 3.9375V9.84375C7.73438 10.0758 7.92422 10.2656 8.15625 10.2656Z" fill="#ADB3BC"/>
          </svg>
          <span class="text-sm text-gray-800">{{ apiToken }}</span>
        </div>
      </div>
      <button
        class="btn self-end min-w-[80px] bg-indigo-500 hover:bg-indigo-600 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
        :disabled="loadingUpdateApiToken"
        @click="changeApiTokenModal = true"
      >
        <svg v-if="loadingUpdateApiToken" class="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
        </svg>
        <span :class="{'ml-2' : loadingUpdateApiToken}">Change</span>
      </button>
    </div>
    
    <div v-if="active_project" class="mt-5">
      <div class="text-sm text-gray-600 font-medium mb-1">Secret key</div>
      <button v-if="!has_tokens" class="btn text-indigo-500 border-gray-200" @click="changeSecretKeyModal = true">Create API Secret Key</button>
      <div v-if="has_tokens" class="flex items-center">
        <div class="h-[38px] w-[350px] mr-2.5 border border-gray-200 rounded flex items-center px-3">
          <span class="text-sm text-gray-800" :style="!showPrivateToken ? 'filter: blur(12px); user-select: none;' : ''">{{ privateToken }}</span>
        </div>
        <div v-if="showPrivateToken">
          <button v-if="privateTokenCopied" class="btn border-green-500 p-2">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.8521 4.87039C20.2698 5.25734 20.2698 5.88305 19.8521 6.23295L9.04253 17.1005C8.69449 17.5203 8.07212 17.5203 7.68723 17.1005L2.11984 11.502C1.7361 11.1521 1.7361 10.5264 2.11984 10.1395C2.50354 9.75665 3.12591 9.75665 3.50957 10.1395L8.38331 15.0422L18.4968 4.87039C18.8817 4.48591 19.5041 4.48591 19.8521 4.87039Z" fill="#44C157"/>
            </svg>
          </button>
          <button v-else class="btn border-gray-200 hover:border-gray-300 p-2" @click="copyPrivateToken()">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.8125 17.7031H12.0312C12.3148 17.7031 12.5469 17.4711 12.5469 17.1875V15.125H14.0938V17.1875C14.0938 18.3251 13.1688 19.25 12.0312 19.25H4.8125C3.67329 19.25 2.75 18.3251 2.75 17.1875V9.96875C2.75 8.83115 3.67329 7.90625 4.8125 7.90625H6.875V9.45312H4.8125C4.52762 9.45312 4.29688 9.68516 4.29688 9.96875V17.1875C4.29688 17.4711 4.52762 17.7031 4.8125 17.7031ZM7.90625 4.8125C7.90625 3.67329 8.83115 2.75 9.96875 2.75H17.1875C18.3251 2.75 19.25 3.67329 19.25 4.8125V12.0312C19.25 13.1688 18.3251 14.0938 17.1875 14.0938H9.96875C8.83115 14.0938 7.90625 13.1688 7.90625 12.0312V4.8125ZM9.96875 12.5469H17.1875C17.4711 12.5469 17.7031 12.3148 17.7031 12.0312V4.8125C17.7031 4.52762 17.4711 4.29688 17.1875 4.29688H9.96875C9.68516 4.29688 9.45312 4.52762 9.45312 4.8125V12.0312C9.45312 12.3148 9.68516 12.5469 9.96875 12.5469Z" fill="#ADB3BC"/>
            </svg>
          </button>
        </div>
        <button
          v-else
          class="btn min-w-[80px] text-gray-600 border-gray-200 hover:border-gray-300"
          :disabled="loadingUpdateSecretToken"
          @click="changeSecretKeyModal = true"
        >
          <svg v-if="loadingUpdateSecretToken" class="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
          </svg>
          <span :class="{'ml-2' : loadingUpdateSecretToken}">Change</span>
        </button>
      </div>
      <div v-if="showPrivateToken" class="mt-5 text-gray-700 text-sm">We strongly recommend that you keep this secret key in a safe and accessible place. If you lose this secret key, you will need to generate a new one.</div>
    </div>
    <div class="mt-3 mb-5 text-sm text-gray-700">
      On the server-side: Must be secret and stored securely in your web or mobile app’s server-side code (such as in an environment variable or credential management system) to call Kod.Mobi APIs. Don’t expose this key on a website or embed it in a mobile application.
    </div>

    <ModalBlank id="change-api-key-modal" :maxWidth="'460px'" :modalOpen="changeApiTokenModal" @close-modal="changeApiTokenModal = false">
      <div class="p-5 flex space-x-4">
        <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
          <svg class="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
          </svg>
        </div>
        <div class="grow">
          <div class="mb-2">
            <div class="text-lg font-semibold text-gray-800">Change API Key</div>
          </div>
          <div class="text-sm mb-5 text-gray-600">
            <div class="space-y-2">
              <p>Do you really want to change your API key? This can lead to irreversible changes</p>
            </div>
          </div>
          <div class="flex flex-wrap justify-end space-x-2">
            <button class="btn min-w-[80px] border-gray-200 hover:border-gray-300 text-gray-600" @click.stop="changeApiTokenModal = false">Cancel</button>
            <button
              class="btn min-w-[80px] bg-rose-500 hover:bg-rose-600 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
              :disabled="loadingUpdateApiToken"
              @click="updateApiToken()"
            >
              <svg v-if="loadingUpdateApiToken" class="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
              <span :class="{'ml-2' : loadingUpdateApiToken}">Change</span>
            </button>
          </div>
        </div>
      </div>
    </ModalBlank>
    <ModalBlank id="change-secret-key-modal" :maxWidth="'500px'" :modalOpen="changeSecretKeyModal" @close-modal="changeSecretKeyModal = false">
      <div class="p-5 flex space-x-4">
        <div class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#E0E7FF"/>
            <path d="M20 12.5C15.8574 12.5 12.5 15.8574 12.5 20C12.5 24.1426 15.8574 27.5 20 27.5C24.1426 27.5 27.5 24.1426 27.5 20C27.5 15.8574 24.1426 12.5 20 12.5ZM19.2969 16.9531C19.2969 16.5664 19.6133 16.25 20 16.25C20.3867 16.25 20.7031 16.5649 20.7031 16.9531V20.7031C20.7031 21.0913 20.3882 21.4062 20 21.4062C19.6118 21.4062 19.2969 21.0928 19.2969 20.7031V16.9531ZM20 24.2188C19.4914 24.2188 19.0789 23.8062 19.0789 23.2977C19.0789 22.7891 19.4911 22.3766 20 22.3766C20.5089 22.3766 20.9211 22.7891 20.9211 23.2977C20.9199 23.8057 20.5098 24.2188 20 24.2188Z" fill="#6366F1"/>
          </svg>
        </div>
        <div class="grow">
          <div class="mb-2">
            <div class="text-lg font-semibold text-gray-800">Change Secret Key</div>
          </div>
          <div class="text-sm mb-5 text-gray-600">
            <div class="space-y-2">
              <p>If you have lost your API secret key, we strongly recommend creating a new one as soon as possible.
                <br />
                If your Key is safe, please do not apply any actions!</p>
            </div>
          </div>
          <div class="flex flex-wrap justify-end space-x-2">
            <button class="btn min-w-[80px] border-gray-200 hover:border-gray-300 text-gray-600" @click.stop="changeSecretKeyModal = false">Cancel</button>
            <button
              class="btn min-w-[80px] bg-indigo-500 hover:bg-indigo-600 text-white"
              :disabled="loadingUpdateSecretToken"
              @click="updateSecretToken()"
            >
              <svg v-if="loadingUpdateSecretToken" class="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
              <span :class="{'ml-2' : loadingUpdateSecretToken}">Create new</span>
            </button>
          </div>
        </div>
      </div>
    </ModalBlank>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ModalBlank from '@/components/Modals/ModalBlank.vue'
import { copy } from 'clipboard'
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
  name: 'SettingsApiKey',
  data: () => ({
    changeApiTokenModal: false,
    privateToken: 'a00000aa-000a-0000-0000-aaaa0a0a0a0a',
    privateTokenCopied: false,
    showPrivateToken: false,
    changeSecretKeyModal: false,
    loadingUpdateApiToken: false,
    loadingUpdateSecretToken: false,
  }),
  components: {
    ModalBlank,
  },
  computed: {
    ...mapFields('ProjectsModule', {
      active_project: 'active_project.project',
      has_tokens: 'active_project.project.has_tokens',
    }),
    apiToken() {
      return this.active_project?.api_key || ''
    },
  },
  methods: {
    copyToken() {
      if(this.active_project?.api_key) {
        copy(this.active_project.api_key)
        toast.success('Successfully copied')
      }
    },
    copyPrivateToken() {
      copy(this.privateToken);
      this.privateTokenCopied = true
      const self = this
      setTimeout(() => {
        self.privateTokenCopied = false
      }, 3000)
    },
    async updateApiToken() {
      try {
        this.loadingUpdateApiToken = true
        await this.$store.dispatch('ProjectsModule/UPDATE_API_TOKEN', this.active_project.id)
        this.changeApiTokenModal = false
        toast.success('Successfully change api token')
      } finally {
        this.loadingUpdateApiToken = false
        this.changeApiTokenModal = false
      }
    },
    async updateSecretToken() {
      try {
        this.loadingUpdateSecretToken = true
        const res = await this.$store.dispatch('ProjectsModule/UPDATE_SECRET_TOKEN', this.active_project.id)
        if (res.success) {
          this.privateToken = res.data.secret;
          this.showPrivateToken = true;
        }
      } finally {
        this.loadingUpdateSecretToken = false
        this.changeSecretKeyModal = false
      }
    },
  },
  beforeDestroy() {
    this.showPrivateToken = false
  }
}
</script>
